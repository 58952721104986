<template>

    <!-- Mobile -->
    <div v-if="isMobile" class="container has-space">
       <MobileHeaderBack></MobileHeaderBack>
        <div>
            <!-- Change Profile Icon -->
            <div class="display-flex-center pointer" @click="handleChangeProfilePicture()">
                <div class="relative mobile-profile__user-icon__wrapper">
                    <div class="mobile-profile__user-icon__container mr-05rem">
                        <img :src="`${s3ImgUrl}/${userInfo.profilePicture}`" class="w-100 h-100">                     
                    </div>
                     <div class="mobile-profile__user-icon__edit"><img src="../../../static/images/icons/icon_pencil.png"></div>
                </div>
            </div>
            <div class="mobile-account__container">
                <!-- nick name -->
                <div class="form-group mb-main">
                    <label class="form-label">{{$t('NICKNAME')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" type="text" maxlength="15" v-model="profile.nickName" v-on:keyup="inputNicknameEvent" :placeholder="$t('NICKNAME')">
                    </div>
                    <div class="form-tips ">
                        <div class="s" :class="{'error': profile.isNicknameError}">{{$t('SIGNUP_NICKNAME_TIP')}}</div>
                    </div>
                </div>
                <div>
                    <button class="btn disable w-100" :class="{'enable':profile.isSaveBtnEnable}" @click="handleSaveProfile">{{$t('SAVE')}}</button>
                </div>
            </div>
        </div>
        <transition name="slide">
            <div v-if="mobile.isShowAvatarSelection" class="profile-modal__wrapper ">
                <div class="container has-space">
                    <div class="mobile-account__header">
                        <div class="mobile-account__header-back" @click="mobile.isShowAvatarSelection = false"> <img src="../../../static/images/icons/icon_arrow_left_white_m.png"></div>
                    </div>
                    <div class="profile-modal__avatar-header">{{$t('CHOOSE_YOUR_AVATAR')}}</div>
                    <div class="mobile-account__container">
                    
                        <div class="profile-modal__avatar-body__list">
                            <div v-for="(o, index) in avatarList" :key="index" class="profile-modal__avatar-body__list-item" :class="{'selected': o.selected}" @click="handleAvatarSelect(o)">
                                    <img :src="`${s3ImgUrl}/profile-pic/${o.name}.png`" class="w-100 h-100">
                            </div>
                        </div>
                        <div>
                            <button class="profile-modal__avatar-body__list-btn" :class="{'enable': avatar.isAvatarBtnEnable}" @click="handleSaveAvatar()">{{$t('CONFIRM')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- Desktop -->
    <AccountIndex v-else>   
        <div class="account-content__wrapper">
            <div class="account-content__title tc">{{$t(title)}}</div>
            <div>
                <!-- Change Profile Icon -->
                <div class="">
                    <div class="display-flex-align-center pointer" @click="handleChangeProfilePicture()">
                        <div class="account-menu__user-icon__wrapper mr-05rem">
                            <img :src="`${s3ImgUrl}/${userInfo.profilePicture}`" class="w-100 h-100">
                        </div>
                        <div ><img src="../../../static/images/icons/icon_pencil.png"></div>
                    </div>
                </div>

                <!-- nick name -->
                <div class="form-group mb-main">
                    <label class="form-label">{{$t('NICKNAME')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" type="text" maxlength="15" v-model="profile.nickName" v-on:keyup="inputNicknameEvent" :placeholder="$t('NICKNAME')">
                    </div>
                    <div class="form-tips ">
                        <div class="s" :class="{'error': profile.isNicknameError}">{{$t('SIGNUP_NICKNAME_TIP')}}</div>
                    </div>
                </div>

                <!-- save profile -->
                <div class="display-flex-fe">
                    <button class="btn disable" :class="{'enable':profile.isSaveBtnEnable}" @click="handleSaveProfile">{{$t('SAVE')}}</button>
                </div>
            </div>
        </div>
    </AccountIndex>
 
    <ModalPopup ref="modalPopupRef">
        <div class="display-flex-center flex-col">
            <div class="profile-modal__avatar-header">{{$t('CHOOSE_YOUR_AVATAR')}}</div>
            <div class="profile-modal__avatar-body">
                <div class="profile-modal__avatar-body__list">
                    <div v-for="(o, index) in avatarList" :key="index" class="profile-modal__avatar-body__list-item" :class="{'selected': o.selected}" @click="handleAvatarSelect(o)">
                          <img :src="`${s3ImgUrl}/profile-pic/${o.name}.png`" class="w-100 h-100">
                    </div>
                </div>
                <div>
                    <button class="profile-modal__avatar-body__list-btn" :class="{'enable': avatar.isAvatarBtnEnable}" @click="handleSaveAvatar()">{{$t('CONFIRM')}}</button>
                </div>
            </div>
        </div>
    </ModalPopup>


</template>

<script>
import config from '@/js/config.js'
import ModalPopup from '@/components/modal/ModalPopup.vue'

import MobileHeaderBack from '@/components/mobile/HeaderBack.vue'
import AccountIndex from '@/views/account/Index.vue'

import {mapGetters,mapActions} from 'vuex'

export default {
    components: {
        ModalPopup,
        AccountIndex,
        MobileHeaderBack
    },
    data() {
        return {
            title: '',
            s3ImgUrl: config.s3ImgUrl,

            profile:{
                isSaveBtnEnable: false,
                nickName: '',
                isNicknameError: false
            },
            
            avatarList: [
                {
                    name: 'avatar-1',
                    selected: false
                },
                {
                    name: 'avatar-2',
                    selected: false
                },
                {
                    name: 'avatar-3',
                    selected: true
                },
                {
                    name: 'avatar-4',
                    selected: false
                },
                {
                    name: 'avatar-5',
                    selected: false
                },
                {
                    name: 'avatar-6',
                    selected: false
                },
                {
                    name: 'avatar-7',
                    selected: false
                },
                {
                    name: 'avatar-8',
                    selected: false
                },
                  {
                    name: 'avatar-9',
                    selected: false
                },
                {
                    name: 'avatar-10',
                    selected: false
                },
            ],

            avatar:{
                selected:'',
                isAvatarBtnEnable: false,
                imageFormat: '.png'
            },

            mobile :{
                isShowAvatarSelection : false
            }
        }
    },
    computed: {
        ...mapGetters([
            'isMobile',
            'currentLocale',
            'isLogin',
            "userInfo"
        ]),
    },
    created() {
        
    },
    beforeUnmount() {
       //window.removeEventListener('keydown', this.listenKeypressEvent)
    },
    mounted() {
          //window.addEventListener('keydown', this.listenKeypressEvent);
        this.init()
    },
    methods: {
          ...mapActions([
            "getRetrieveUserInfo",
            "postUpdateProfilePicture",
            "postUpdateProfile",
            "setUserData",
            "toastrChangeEvent",
            "commonVerifyLogin"
        ]),
        init() {
            this.commonVerifyLogin();
            
            this.title = this.$route.meta.name;
        },

        inputNicknameEvent() {
            let minLength = 4
            if (this.profile.nickName.length >= minLength && this.$tools.noSymbolVerification(this.profile.nickName)) {
                this.profile.isSaveBtnEnable = true;
                this.profile.isNicknameError = false;
            } else {
                this.profile.isSaveBtnEnable = false;
                this.profile.isNicknameError = true;
            }
            if (this.$tools.noNumberVerification(this.profile.nickName)) {
                this.profile.isSaveBtnEnable = false;
                this.profile.isNicknameError = true;
            }
        },

        handleChangeProfilePicture() {
            this.resetAvatar();
            if (this.isMobile) {
                this.mobile.isShowAvatarSelection = true;
            } else {           
                this.$refs.modalPopupRef.handleOpen();
            }
            
            this.avatarList.forEach(x=>{
                if (this.userInfo.profilePicture === `profile-pic/${x.name}.png`) {
                    x.selected = true;
                } else {
                    x.selected = false;
                }
            })
        },

        handleAvatarSelect(o) {
          this.avatarList.forEach(x=>{
               if (x.name === o.name) {
                    x.selected = true;
                    this.avatar.selectedPic = x.name
                } else {
                    x.selected = false;
                }
                this.avatar.isAvatarBtnEnable = true;
          })
        },
        resetAvatar() {
            this.avatar.selectedPic = "";
            this.avatar.isAvatarBtnEnable = false;
        },
        // get user info
        async getRetrieveUserInfoData() {
            let params = {
                token: this.userInfo.token,
                timeZone:this.$tools.getCurrentTimeZone()
            }
            const result = await this.getRetrieveUserInfo(params);
            this.setUserData(result.result.data);
        },

        // save avatar picture
        async handleSaveAvatar() {
            let params = {
                picUrl: this.avatar.selectedPic + this.avatar.imageFormat,
                token: this.userInfo.token
            }

            const result = await this.postUpdateProfilePicture(params);
        
            this.getRetrieveUserInfoData();
            this.$refs.modalPopupRef.handleClose();

            let paramsToastr = {
                isOpen:true,
                msg:'',
                type:''
            }
            if (result.result.businessCode === 0) {
                paramsToastr.msg = 'UPDATED_SUCCESSFULLY';
                //this.$root.toastrMsg = 'UPDATED_SUCCESSFULLY';
            } else {
                paramsToastr.msg = result.result.message;
                paramsToastr.type = 'info';
                //this.$root.toastrMsg = result.result.message;

                 this.$root.toastrType = 'info'
            }
            this.toastrChangeEvent(paramsToastr)
            //this.$root.toastrPosition = "top";
            //this.$root.$refs.toastrRef.handleOpen();
        },

        // save nickname
        async handleSaveProfile() {
            if (!this.profile.isSaveBtnEnable) {
                return
            }

            let params = {
                newDisplayName: this.profile.nickName,
                preferredLanguage: this.currentLocale,
                token: this.userInfo.token
            };

            const result = await this.postUpdateProfile(params);

            let paramsToastr = {
                isOpen:true,
                msg:'',
                type:''
            }
            if (result.result.businessCode === 0) {
                paramsToastr.msg = 'UPDATED_SUCCESSFULLY';
                this.$root.toastrMsg = 'UPDATED_SUCCESSFULLY';
                this.getRetrieveUserInfoData();
            } else {
                paramsToastr.msg = result.result.message;
                paramsToastr.type = "info";
                 //this.$root.toastrMsg = result.result.message;              
                 //this.$root.toastrType = 'info'
            }
            this.toastrChangeEvent(paramsToastr)
            // this.$root.toastrPosition = "top";
            // this.$root.$refs.toastrRef.handleOpen(); 
        },

        listenKeypressEvent(e) {
             if (e.key === "Enter") {
               this.handleSaveProfile();
            } 
        }
    },
}
</script>

<style>
.profile-modal__wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-theme);
    z-index: 10;
    overflow-y: auto;
}

.profile-modal__avatar-header{
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1.96875rem;
}
.profile-modal__avatar-body__list{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    margin-bottom: 1.8rem;
}
.profile-modal__avatar-body__list-item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8125rem;
    height: 4.8125rem;
    border-radius: 50%;
    border: 0.35rem solid #4b616c;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 1px 3px 2px 0px #404040;
    cursor: pointer;
    transition: all .3s;
}
.profile-modal__avatar-body__list-item.selected{
    border-color: var(--bg-selected);
}
.profile-modal__avatar-body__list-item:hover{
    border-color: var(--bg-selected);
}
.profile-modal__avatar-body__list-btn{
    width: 100%;
    padding: 0.625rem;
    font-weight: 700;
    font-size: 0.875rem;
    background-color: var(--color-theme-2);
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    pointer-events: none;
    color: black;
}
.profile-modal__avatar-body__list-btn.enable{
    background-color: var(--bg-selected);
    pointer-events: fill;
}

/* mobile */
.mobile-profile__user-icon__wrapper{
    margin: 3.859rem 0;
}

.mobile-profile__user-icon__container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5.6875rem;
    height: 5.6875rem;
    border-radius: 50%;
    border: .5rem solid var(--bg-selected);

    overflow: hidden;
}
.mobile-profile__user-icon__edit{
    position: absolute;
    right: 0rem;
    bottom: -.2rem;
}
.mobile-profile__setting-wrapper{
    padding: 0 1.2rem
}
@media (max-width: 768px){
    .profile-modal__avatar-body__list {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1.2rem;
        row-gap: 2rem;
    }
    .profile-modal__avatar-body__list-item{
        width: 100%;
        height: 95%;
    }
    .profile-modal__avatar-header{
            padding-left: .7rem;
    }
}

</style>